import { CommonTagService } from '@/api';
import { messageError } from '@/utils';
import { Input } from 'element-ui';
import { ElInput } from 'element-ui/types/input';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class CommonTags extends Vue {
  public dynamicTags: Array<string> = [];
  public inputVisible: boolean = false;
  public inputValue: string = '';
  public handleClose(tag: string): void {
    this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    const tags = JSON.stringify(this.dynamicTags);
    this.updateTags(tags);
  }

  public showInput(): void {
    this.inputVisible = true;
    this.$nextTick(() => {
      ((this.$refs.saveTagInput as ElInput).$refs.input as Input).focus();
    });
  }

  public handleInputConfirm(): void {
    const inputValue = this.inputValue;
    if (inputValue) {
      this.dynamicTags.push(inputValue);
      const tags = JSON.stringify(this.dynamicTags);
      this.updateTags(tags);
    }
    this.inputVisible = false;
    this.inputValue = '';
  }

  public getTags(): void {
    CommonTagService.getTagList()
      .then(res => {
        this.dynamicTags = JSON.parse(res);
      })
      .catch(error => {
        messageError(error);
      });
  }
  public updateTags(tags: string): void {
    CommonTagService.put(tags)
      .then(() => {
        this.getTags();
      })
      .catch(error => {
        messageError(error);
      });
  }

  public activated(): void {
    this.getTags();
  }
  public created(): void {
    this.getTags();
  }
}
